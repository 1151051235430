import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import './index.css';
import Viewall from './component/Admin/viewAll';
import LoginScreen from './screens/LoginScreen';
import RegisterPage from './screens/RegisterScreen';
import { AlertProvider } from './component/alert/useAlert';
import {Hommie} from './component/portals/portals';
import { Service } from './screens/services/services';
import { Data } from './screens/utilities/data';
import { Help } from './screens/help/help';
import { Message } from './screens/Messages';
import { Notificaton } from './screens/notification';
import { Profile } from './screens/profile/profle';
import { Settings } from './screens/settings/settings';
import { Paystack } from './screens/help/deposite';
import { Airtime } from './screens/utilities/Airtime';
import { Receipt } from './screens/services/receipt';
import { ProtectedRoute } from './screens/protectedroute/indes';
import { LandingPage } from './component/landing';
import { Developer } from './developer';
import { Finance } from './component/finance';
import { Terms } from './component/terms';
import { AppOverview } from './component/about';
import { LandingPageOutlet } from './component/LandingOutlet';
import Notfound from './component/notfound';
import { Project } from './screens/project';
import { AdminDashboard } from './component/Admin/dashboard';
import MyLineChart from './component/Admin/dashboard/search';
import FindUser from './component/Admin/dashboard/user';
import { UserDetail } from './component/Admin/dashboard/View';
import SendMessage from './component/Admin/dashboard/Email';
import { SendMessageDirect } from './component/Admin/dashboard/sendm/sendM';
import { ProtectedLogin } from './screens/auth/protectLogin';
import { Dashboard } from './component/Dashboard';
import { DeleteApp } from './deleteaccount/deleteAccount';
import BenefitOverlay from './component/convertpoin';
import { Transaction } from './screens/trans&receipt/transactions';


function App() {
  return (
    <AlertProvider>
      <Router>
        <Routes>
          <Route path='login' element={<ProtectedLogin />}>
        <Route path="" element={<LoginScreen />} />
        <Route path="register" element={<RegisterPage />} />
        </Route>
        <Route path='register' element={<ProtectedLogin />}>
        <Route path="" element={<RegisterPage />} />
        </Route>
        {/* Admin Login  */}
         <Route path='/admin' element={
          <AdminDashboard />}
          >
          <Route path='' element={<MyLineChart />} />
          <Route path="find" element={<FindUser />} />
          <Route path="user-details" element={<UserDetail />} />
          <Route path ="send" element={<SendMessage />} />
          <Route path ="send-message" element={<SendMessageDirect />} /> 
          <Route path='check' element={<Viewall />} />
         </Route>

        <Route path="/ai" element={<Hommie />} />
          <Route path="/" element={<LandingPageOutlet />} >
          <Route path="delete" element={<DeleteApp />} />
            <Route path='/' element={<LandingPage />} />
            <Route path="developer" element={<Developer />} />
            <Route path="terms" element={<Terms />} />
            <Route path="about" element={<AppOverview />} />
            <Route path="project"element={<Project />} />
          </Route>
          <Route
            path="/home"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          >
            <Route index element={<Service />} />
            <Route path="data" element={<Data />} />
            <Route path="help" element={<Help />} />
            <Route path="message" element={<Message />} />
            <Route path="notification" element={<Notificaton />} />
            <Route path="profile" element={<Profile />} />
            <Route path="setting" element={<Settings />} />
            <Route path="airtime" element={<Airtime />} />
            <Route path="receipt" element={<Receipt />} />
            <Route path="finance" element={<Finance />} />
            <Route path="benefit" element={<BenefitOverlay />}/>
            <Route path='trans' element={<Transaction />}/>
          </Route>
          <Route path="pay" element={<Paystack />} />
          <Route path="receipt" element={<Receipt />} />
          <Route path="*"  element={<Notfound />} />
        </Routes>
      </Router>
    </AlertProvider>
  );
}

export default App;
