import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const BottomSheet = ({ isOpen, plan, phone, price,status }) => {
    const navigate = useNavigate();

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-end z-50">
            <div className="bg-white w-full sm:w-2/3 lg:w-1/2 xl:w-1/3 h-1/2 rounded-t-lg shadow-lg p-4">
                <div className="text-center mb-4">
                    <p className="mt-2 text-gray-700">
                        Processing <i className="animate-ping text-xl">....</i>
                    </p>
                </div>
                <div className="flex justify-center mb-4">
                    <Button 
                        variant="contained"
                        color="primary"
                        onClick={() => navigate("/home")}
                    >
                    Click Here
                    </Button>
                </div>
                <p className="text-center text-gray-500">Your Airtime/Data will be processed in a secound</p>
                <p className="text-center text-sm text-red-300"> If you have not been debited kindly regards the purchased as failed purchased</p>
            </div>
        </div>
    );
};
