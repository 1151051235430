import React from "react";
import { FaPhone, FaWifi, FaSalesforce, FaBars, FaPhoenixFramework } from "react-icons/fa";
import { FaPhoneSlash } from "react-icons/fa6";

import { useNavigate } from "react-router-dom";
import { useAlert } from "../../component/alert/useAlert";
import { Scollling } from "./scrollservices";


export const Allserv = () => {
  const navigate = useNavigate();
  const showAlert = useAlert();
  

  const HandleAll = () => {
    showAlert("All other utilities not available on the web", "success");
  };
  return (

    <>
      
    <div className="p-4 grid grid-cols-4 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4">
      {/* Grid Items */}
<div className="bg-white rounded-lg p-2 m-1 ">
  <center>
      <button 
        onClick={() => window.open('https://wa.me/+2349061229992', '_blank')}
        className=""
      >
        
          <FaPhoneSlash className="text-sm"  />
       
      </button>
      <p style={{
          fontSize:12
        }} className="text-gray-500">Cards</p>
        </center>
</div>


<div className="bg-white rounded-lg p-2 m-1 ">
  <center>
      <button
        onClick={() => navigate('airtime')}
      >
          <FaPhone className="text-sm"  />
      </button>
      <p style={{
          fontSize:12
        }} className="text-gray-500"> Airtime</p>
        </center>
</div>


<div className="bg-white rounded-lg p-2 m-1 ">
<center>
<button
        onClick={() => navigate("data")}
       
      >
          <FaWifi className="text-sm"  />
      
      </button>

      <p style={{
          fontSize:12
        }} className="text-gray-500">Data</p>
  </center>
  
</div>
    


<div className="bg-white rounded-lg p-2 m-1 ">
  <center>
<button
        onClick={HandleAll}
      >
       
          <FaBars className="text-sm" />
      </button>

      <p style={{
          fontSize:12
        }} className="text-gray-500">More</p>
</center>
</div>

 



    </div>

       
   
    </>
  );
};
