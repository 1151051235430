import React, { useState } from "react";
import { FaArrowLeft, FaMailBulk, FaWhatsapp } from "react-icons/fa";
import { FaExplosion } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import ModalSettings from "./modals";

export const Settings = () => {
  const [modalOpen, setModalOpen] = useState(false);
const navigate = useNavigate()
  const handleClose = () => {
    setModalOpen(false);
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  return (
    <div className=" p-2 m-2  flex-1">
<FaArrowLeft className="m-3"  onClick={()=>navigate("/home")} />

<h2 className="text-gray-600 text-center p-2">GENERAL SETTINGS</h2>
      
      <h4 className="text-left text-sm  m-2">Personal Settings</h4>
        <p>
        <button
        className="text-sm p-2 text-blue-900"
            onClick={() => window.open("https://www.softnixx.com/api/password_reset/")}>
            
            Change Password
          </button>
        </p>
         
          <button
            className="text-sm p-2 text-blue-900"
            onClick={handleOpen}
            
          >
            Change Transaction PIN
          </button>
        
    
    
          <label className="block text-sm p-2  text-gray-700 mb-1">
            New Phone Number:
          </label>
          <input
            type="tel"
           disabled={true}
            className="w-full p-2 border rounded-lg mb-3 focus:ring focus:ring-blue-500"
            placeholder="Enter new phone number"
          />
          <button
          disabled
            type="submit"
            className="text-sm p-1 bg-gray-300 text-gray950 rounded-lg"
          >
            Update
          </button>
        
    

    <center>
    <h3 className="text-white bg-gray-500 p-2 rounded-lg m-3">Do you need help? text us here</h3>
    </center>


      <div className="flex justify-center space-x-6">

          <a
            href="mailto:samuelyyyy257@gmail.com"
            className="bg-gray-800 p-4 rounded-full flex items-center justify-center text-white shadow-lg hover:bg-gray-700 transition duration-300 ease-in-out"
          >
            <FaMailBulk className="text-2xl" />
          </a>
          <a
            href="https://wa.me/1234567890" // Replace with actual phone number
            target="_blank"
            rel="noopener noreferrer"
            className="bg-green-600 p-4 rounded-full flex items-center justify-center text-white shadow-lg hover:bg-green-700 transition duration-300 ease-in-out"
          >
            <FaWhatsapp className="text-2xl" />
          </a>
          <a
            href="#"
            className="bg-purple-600 p-4 rounded-full flex items-center justify-center text-white shadow-lg hover:bg-purple-700 transition duration-300 ease-in-out"
          >
            <FaExplosion className="text-2xl" />
          </a>
        </div>


      <ModalSettings open={modalOpen} onClose={handleClose} />

      <p className="text-gray-500 mt-3 text-center">For more settings Please download our mobile App</p>
    </div>
  );
};
