import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import { CgClose } from "react-icons/cg";
import { FaAngleDown, FaHandPointDown, FaPeopleArrows } from "react-icons/fa";
import { FaPeopleGroup } from "react-icons/fa6";

const ReferModal = ({ open, close, user,copy }) => {
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width:"100%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          borderRadius: "8px",
          textAlign: "center",
        }}
      >

<CgClose onClick={close} className="text-red-400 m-2 shadow-lg text-2xl" />
        <Typography id="modal-title" variant="h5" component="h2">
          Hi, {user|| "Guest"}! <span className="text-sm text-green-500 ">Join Our referer?.</span>
        </Typography>
         
        <p>What is new?</p>
<center>
<FaPeopleGroup className="text-4xl text-gray-700 " />
</center>

        <Typography id="modal-description" className="text-justify" sx={{ mt: 1 }}>
          Copy the link bellow and paste it to anyone you want to refer, when they succesfully register your account will be credited 
          with exactly N500 per user that succesfully register.

          No deposit no Transactions just refer and earn.
        </Typography>
<center>
<FaHandPointDown className="animate-ping mt-4" />
</center>
        <Button onClick={copy} sx={{ mb: 4 }}  variant="contained" color='secondary'  >
          Refer and Earn now
        </Button>
      </Box>
    </Modal>
  );
};

export default ReferModal;
