import React, { useState } from "react";
// Import the CSS file for styling
import { Input } from "../component/InputComp/Input";
import { FaCheckCircle, FaEye, FaEyeSlash } from "react-icons/fa"; // Font Awesome Icons from React Icons
import { Button } from "../component/Button/Button";
import { useAlert } from "../component/alert/useAlert";
import { Loading } from "../component/Loader/loader";
import { Checkbox } from "@mui/material";
import {CircularProgress} from "@mui/material";
import { useNavigate } from "react-router-dom";
export const RegisterScreen = () => {
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [retype, setRetype] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [checked, setchecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [referer, setReferer] = useState(null);


  React.useEffect(() => {
      const queryParams = new URLSearchParams(window.location.search);
      const refererValue = queryParams.get("referer");
      setReferer(refererValue);
  }, []);
  
  const navigate = useNavigate()

  const showAlert = useAlert();

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleRetypeChange = (e) => {
    setRetype(e.target.value);
  };

  const handleFirstnameChange = (e) => {
    setFirstname(e.target.value);
  };

  const handleLastnameChange = (e) => {
    setLastname(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleRegister = async () => {
    setIsLoading(true);
    if(!checked){
        showAlert('Please accept terms and condictions!', 'error');
    }
    else{
    try {
      // Example registration logic
      const response = await fetch(`https://www.softnixx.com/api/register/?referer=${referer}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
            username: username.trim(),
            password: password.trim(),
            lastname: lastname.trim(),
            firstname: firstname.trim(),
            email: email.trim(),
            passwordR:retype.trim(),
          }),
      });
      const data = await response.json();
      if (response.ok) {
showAlert(data.message, "success")
      } else {
        // Handle registration error
        showAlert(data.message,"error");
      }
    } catch (error) {
      showAlert("something went wrong","error")
    } finally {
      setIsLoading(false);
    }
}
setIsLoading(false)
  };

  return (
    <>
      <div className="ov-al">
        <div className="fl-1">
          <img
            className="log-im"
            src={require("../comp.jpg")}
            alt="Placeholder"
          />
          <p className="p-1">Download our app and get bonuses.</p>
          <Button title={"Download now"} />
        </div>

        <div className="fl-2">
          <div className="card-container">
            <marquee className="mark-label">
              <p>
               The best Utility management platform, Bringng all at once
              </p>
            </marquee>
            <h4 className="h4-label">Register</h4>
            <center>
              <Input
                handleChange={handleUsernameChange}
                value={username}
                placeholder={"Your username"}
                label={"Username"}
              />
              <Input
                handleChange={handleEmailChange}
                value={email}
                encrpt={email}
                placeholder={"Your Email"}
                label={"Email"}
              />
              <Input
                handleChange={handleFirstnameChange}
                value={firstname}
                placeholder={"Your Firstname"}
                label={"Your firstname"}
              />
              <Input
                handleChange={handleLastnameChange}
                value={lastname}
                placeholder={"Your Lastname"}
                label={"Lastname"}
              />

              <div className="fa-t">
                <Input
                  handleChange={handlePasswordChange}
                  encrpt={"password"}
                  value={password}
                  placeholder={"Password"}
                  label={"Password"}
                />
              </div>

              <div className="fa-t">
                <Input
                  handleChange={handleRetypeChange}
                  encrpt={"password"}
                  value={retype}
                  placeholder={"re-Password"}
                  label={"re-Password"}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems:"center"
                }}
              >
                <Checkbox
                  checked={checked}
                  onClick={() => setchecked(!checked)}
                />
                <h3>Agree to terms and condictions</h3>
              </div>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <Button handlSubmit={handleRegister} title={"Submit"} />
              )}
            </center>
          </div>
          <center>
            <div className="over">
              
              <button onClick={()=>window.open("https://www.softnixx.com/api/password_reset/")} className="first-b">
                Forgot password?
              </button>
              <button onClick={()=>navigate("/login")} className="secound-b">Login</button>
            </div>
          </center>
        </div>
      </div>
    </>
  );
};
